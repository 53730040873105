
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { finalize, take, takeUntil } from 'rxjs/operators'
import { Observable, of, Subject } from 'rxjs'
import get from 'lodash-es/get'
import set from 'lodash-es/set'

// Services
import { FormService } from './../../../services/form.service'
import { ConfigurationService } from './../../../services/configuration.service'
import { LocationService } from '../../../services/location.service'
import { LogService } from './../../../services/log.service'
import { SearchService } from 'src/app/services/search.service'
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'

// Interfaces
import { ISmartConsoleFunctions } from 'src/app/interfaces/logs/smart-console-functions.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IFormConfiguration, IFormDefaultFieldConfiguration, IFormFieldConfiguration, IPropertyValueMap } from 'src/app/interfaces/components/component-form.interface'
import { TagManagerService } from 'src/app/services/tag-manager.service'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ReCaptchaService } from 'src/app/services/recaptcha.service'

export const FormComponentDefaults: IFormConfiguration = {
    type: 'form',
    display: true,
    title: '',
    titleStyles: {},
    subtitle: '',
    subtitleStyles: {},
    disclaimer: '',
    styles: '',
    messageSuccessDataConfiguration: {
        data_group: '',
        display_type: '',
        limit: 0,
        list_configuration: {},
    },
    messageSuccess: '<h1 class="is-font-h1 mb-1">Thanks for contacting us! We\'ll get back to you soon.</h1><p>This information will not be shared with any dealer other than those you select. <a href="#">Our collection and use of your personal information</a></p>',
    messageError: '<h1 class="is-font-h1">Something must be wrong<h1><p>Retry or contact us if the problem persists</p>',
    includeSuccessCloseButton: true,
    hasRecatpcha: true,
    cssClass: '',
    fields: [],
    defaultFields: [{
        getValueFrom: 'service.route.url',
        key: 'page_url'
    }],
    id: '',
    containerStyles: {},
    formType: '',
    headerStyles: {},
    disclaimerStyles: {},
    hasTopMargin: true,
    isBordered: true,
    isBackgroundSecondaryLight: true,
    shadedContainer: false,
    disableRounding: false,
    testMode: false,
    testModeSuccess: true,
    financedBy: '',
    financingCurrencyCode: 'USD',
    financingCurrencySymbol: '$',
}

export const FormComponentDevOpts: IComponentDevOptions = {
    config: {
        ...FormComponentDefaults,
        testMode: true,
        formType: '',
        id: 'test_form',
        title: 'Form Title',
        subtitle: 'Form Subtitle',
        disclaimer: 'Disclaimer text',
        fields: [
            [ // row 1
                {
                    type: 'text',
                    label: 'First name*',
                    key: 'first_name',
                    required: true,
                    error: 'First name is required',
                    field_styles: {
                        width: '33%',
                    },
                    value: 'Some',
                }, {
                    type: 'text',
                    label: 'Middle name',
                    key: 'last_name',
                    required: false,
                    field_styles: {
                        width: '33%',
                    },
                    value: 'Test',
                }, {
                    type: 'text',
                    label: 'Last name*',
                    key: 'last_name',
                    required: true,
                    error: 'Last name is required',
                    field_styles: {
                        width: '33%',
                    },
                    value: 'Name',
                }
            ], [ // row 2
                {
                    type: 'dropdown',
                    label: 'Dropdown',
                    key: 'dropdown',
                    options: [{
                        label: '',
                        value: '',
                    }, {
                        label: 'Opt 1',
                        value: '1',
                    }, {
                        label: 'Opt 2',
                        value: '2',
                    }],
                    required: false,
                    field_styles: {
                        width: '50%',
                    },
                }, {
                    type: 'tel',
                    label: 'Phone #*',
                    key: 'phone',
                    required: true,
                    error: 'Phone # is required',
                    field_styles: {
                        width: '50%',
                    },
                    value: '1234567890',
                }
            ], [ // row 3
                {
                    type: 'email',
                    label: 'Email',
                    key: 'email',
                    required: false,
                }
            ], [ // row 4
                {
                    type: 'recaptcha',
                    required: true,
                }, {
                    type: 'submit',
                    content: 'Submit Form',
                    styles: {
                        'background-color': '#333',
                        color: '#FFF',
                        width: '100%',
                    },
                    field_styles: {
                        'flex-grow': '1',
                    },
                    required: false,
                }
            ]
        ],
        messageSuccessDataConfiguration: {
            data_group: 'featured-equipment',
            limit: 3,
            list_configuration: {
                displayType: 'search-result-inline',
                link: '/equipment/detail/{{equipment_number}}',
                childConfigs: {
                    list_item: {
                        showImage: true,
                        showTitle: true,
                        showPrice: true,
                        showMeter: true,
                        showLocation: true,
                    }
                }
            }
        }
    },
}

@Component({
    selector: '[ras-form]',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormComponent implements OnInit, OnDestroy, IBaseComponent {
    private debug: ISmartConsoleFunctions
    private $destroy = new Subject<void>()
    private propertyValueMap: IPropertyValueMap = {}

    @Input() configuration: IFormConfiguration

    public recaptchaKey: string
    public isLoading = false
    public isSuccess = false
    public isError = false
    public form: UntypedFormGroup
    public token: string
    public params: any
    public data: Array<any> = []
    public dataDisplayType: string
    public isLoadingData: Observable<boolean>
    public financeData

    @HostBinding('class') get setClass() { return this.configuration.cssClass ? this.configuration.cssClass : ''}

    constructor(
        private formService: FormService,
        private configurationService: ConfigurationService,
        private locationService: LocationService,
        private searchService: SearchService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private tagManagerService: TagManagerService,
        private themeService: ThemeService,
        private recaptchaService: ReCaptchaService,
        @Inject( Window ) private window,
        private elRef: ElementRef,
        private activatedRoute: ActivatedRoute,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
            this.ref.detectChanges()
        })
    }

    ngOnInit() {
        this.debug = LogService.startDebug(this.configurationService.getConfig('catalog.client_name', 'Catalog-ng'), 'Contact Form(S)', 'warning')
        UtilityService.populateConfigDefaults(this.configuration, FormComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        this.ref.markForCheck()

        const propertyValueList: Array<{ to: string, from: Observable<any> }> = [
            { to: 'service.location.location', from: this.locationService.location$ },
            { to: 'service.route', from: of( this.window.location.href ) }
        ]

        propertyValueList.forEach( ({ to, from }) => {
            from.pipe( takeUntil( this.$destroy ) )
                .subscribe( value => set( this.propertyValueMap, to, value ) )
        })

        this.form = this.getFormGroup( this.configuration.fields )

        this.route.params
            .pipe(
                takeUntil( this.$destroy )
            )
            .subscribe( params => {
                this.params = params
                this.isError = false
                this.isSuccess = false
                this.propertyValueMap.service.route  = { url: this.window.location.href }

                // Reset the form but allowing the default values from the configuration
                this.form.reset(
                    this.getDefaultValues( this.configuration.fields )
                )
                this.ref.detectChanges()
            })

        // Request data used for the Form
        if( this.configuration.dataGroup ) {
            this.searchService.requestData( this.configuration.dataGroup )
                .pipe( takeUntil( this.$destroy ) )
                .subscribe( data => this.propertyValueMap.service.data = data )
        }

        // Request data used for the success equipment list
        if(this.configuration.messageSuccessDataConfiguration &&this.configuration.messageSuccessDataConfiguration.data_group) {
            this.dataDisplayType = this.configuration.messageSuccessDataConfiguration.display_type
            this.isLoadingData = this.searchService.isLoading(this.configuration.messageSuccessDataConfiguration.data_group)

            this.searchService.requestData(this.configuration.messageSuccessDataConfiguration.data_group)
                .pipe(
                    take(1),
                    takeUntil(this.$destroy)
                )
                .subscribe( ({data}) => {
                    this.data = data ? [...data] : []
                    if(this.configuration.messageSuccessDataConfiguration.limit && this.data.length) {
                        this.data.length = this.configuration.messageSuccessDataConfiguration.limit
                    }
                    this.ref.markForCheck()
                })
        }

        // update any dropdown fields that want to populate data from the api
        this.configuration.fields.forEach(fieldGroup => {
            fieldGroup.forEach(field => {
                const { populateFrom } = field
                if (
                    field.type === 'dropdown'
                    && !!populateFrom?.dataGroup
                ) {
                    this.searchService.requestData(field.populateFrom.dataGroup)
                        .pipe(takeUntil(this.$destroy))
                        .subscribe(data => {
                            field.options = []
                            if (!!populateFrom.includeEmpty) {
                                field.options.push({
                                    label: '',
                                    value: ''
                                })
                            }
                            data.data.forEach(d => {
                                const opt = {
                                    label: d[populateFrom.label],
                                    value: d[populateFrom.value]
                                }
                                field.options.push(opt)
                            })
                        })
                }
            })
        })
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onSubmit() {
        if (!!this.configuration.testMode) {
            console.log('Form submit: ', this.getFormData())
            this.isSuccess = !!this.configuration.testModeSuccess
            this.isError = !this.configuration.testModeSuccess
            return
        }
        this.isLoading = true
        if (this.configuration.hasRecatpcha) {
            this.recaptchaService
                .execute(this.configuration.cssClass)
                .then(token => {
                    if( !token ) {
                        this.debug.error( { message: 'Error while executing recaptcha' } )
                    } else {
                        this.token = token
                        switch (this.configuration.formType) {
                            case 'financing_quote':
                                this.submitFinance()
                                break
                            default:
                                this.submitContact()
                        }
                    }
                })
        }
    }

    submitContact() {
        this.formService
            .sendContact( this.getFormData() )
            .pipe(
                takeUntil(this.$destroy),
                finalize( () => {
                    this.isLoading = false
                    this.ref.markForCheck()
                })
            )
            .subscribe( ( data: any ) => {
                this.isSuccess = !!data.success
                this.isError = !!!data.success
                this.form.reset()
                if (this.isSuccess && !!this.configuration.tagManagerSuccessEvent) {
                    this.tagManagerService.push({
                        event: this.configuration.tagManagerSuccessEvent,
                    })
                }
            }, error => {
                this.isError = true
                this.debug.error( { message: 'Error while sending the contact form', error: error } )
            })
    }

    submitFinance() {
        this.formService
            .sendFinanceQuote(this.getFormData())
            .pipe(
                takeUntil(this.$destroy),
                finalize( () => {
                    this.isLoading = false
                    this.ref.markForCheck()
                })
            )
            .subscribe((data: any) => {
                this.financeData = data
                this.financeData.data = this.financeData.data?.map(d => ({
                        ...d,
                        payment_native: Math.round(d.payment_native)
                    }))
                this.themeService.setStyleOnElement('calculated-data-length', this.financeData.data.length, this.elRef)
                this.isSuccess = true
                this.isError = false
                this.form.reset()
                if (this.isSuccess && !!this.configuration.tagManagerSuccessEvent) {
                    this.tagManagerService.push({
                        event: this.configuration.tagManagerSuccessEvent,
                    })
                }
            }, error => {
                this.isError = true
                this.debug.error( { message: 'Error while sending the finance form', error: error } )
            })
    }

    onCloseOverlay() {
        this.isError = false
        this.isSuccess = false
        this.ref.markForCheck()
    }

    getDefaultFieldValue( field: IFormDefaultFieldConfiguration ): any {
        if( field && field.getValueFrom ) {
            const fieldValue = get( this.propertyValueMap, field.getValueFrom, '' )
            return fieldValue
        }

        return  field.value ? field.value : ''
    }

    getFieldId( key: string ) {
        return `${( this.configuration.id ? this.configuration.id : '' )}_${key}`
    }

    private getFormGroup( fields: Array<Array<IFormFieldConfiguration>> ) {
        const formGroup = {}

        fields.forEach(row =>
            row.filter(r => !!r.key).forEach(field => {
                const validators = this.getValidators( field )
                const defaultValue = field.value ? field.value : ''
                formGroup[ field.key ] = new UntypedFormControl( defaultValue, validators)
            })
        )

        return new UntypedFormGroup( formGroup )
    }

    private getDefaultValues( fields: Array<Array<IFormFieldConfiguration>> ) {
        const values = {}

        fields.forEach(row =>
            row.filter(r => !!r.key).forEach(field => {
                let defaultValue = ''
                if ('value' in field) {
                    defaultValue = field.value
                    if (typeof field.value === 'string') {
                        const valPieces = field.value.split('.')
                        if (valPieces.length > 1 && valPieces[0] === 'params') {
                            defaultValue = this.params[valPieces[1]] ?? ''
                        }
                    }
                }
                values[ field.key ] = defaultValue
            })
        )

        return values
    }

    private getFormData() {

        const data: any = {}

        // Form data
        for ( const field of Object.keys( this.form.controls ) ) {
            data[field] = this.form.controls[ field ].value
        }

        // Fixed data
        data.token = this.token
        const { module } = this.activatedRoute.snapshot.data
        let fromPage = module
        if (fromPage === 'detail') {
            fromPage = `${this.params.assetType}_detail`
        }
        data.from_page = fromPage
        data.form_type = this.configuration.formType
        if (this.configuration.formType === 'newsletter') {
            data.join_newsletter = true
        }

        switch( this.params.assetType ) {
            case 'equipment':
                data.equipment_number = decodeURIComponent(this.params.assetId)
                break
            default:
                data.subcategory_id = decodeURIComponent(this.params.assetId)
                break
        }

        // Default data
        if( this.configuration.defaultFields ) {
            this.configuration.defaultFields.forEach( field => {
                data[ field.key ] = this.getDefaultFieldValue( field )
            })
        }

        return  data
    }

    private getValidators( field: IFormFieldConfiguration ) {
        const validations = []

        if( field.required ) {
            validations.push( Validators.required )
        }
        if( field.type === 'email' ) {
            validations.push( Validators.pattern( /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/) )
        }
        if (field.required && !!field.validators) {
            field.validators.forEach(validator => {
                switch (validator.type) {
                    case 'phone':
                        validations.push(Validators.pattern(/^[\(\)\s0-9\-]*$/))
                        break
                    case 'min':
                        validations.push(Validators.pattern(/^[0-9]*$/))
                        validations.push(Validators.min(validator.data))
                        break
                    case 'max':
                        validations.push(Validators.pattern(/^[0-9]*$/))
                        validations.push(Validators.max(validator.data))
                        break
                }
            })
        }

        return { validators: validations }
    }
}
